import React from "react"
import Helmet from "react-helmet"

export function Seo(props) {
  const { siteTitle, href, seo } = props

  const {
    title,
    metaDesc,
    opengraphTitle,
    opengraphDescription,
    opengraphImage
  } = seo || {}

  return (
    <Helmet>
      {title && <title>{title}</title>}

      {metaDesc && <meta name="description" content={metaDesc} />}

      <meta property="og:type" content="website" />

      <meta name="robots" content="noindex" />

      {(!!opengraphDescription || !!metaDesc) && (
        <meta
          property="og:description"
          content={opengraphDescription ? opengraphDescription : metaDesc}
        />
      )}

      {opengraphImage && (
        <meta property="og:image" content={opengraphImage.sourceUrl} />
      )}

      {siteTitle && <meta property="og:site_name" content={siteTitle} />}

      {href && <meta property="og:url" content={href} />}

      {(opengraphTitle || title) && (
        <meta property="og:title" content={opengraphTitle || title} />
      )}

      <meta name="twitter:card" content="summary" />

      {opengraphImage && (
        <meta property="twitter:image" content={opengraphImage.sourceUrl} />
      )}

      {(opengraphTitle || title) && (
        <meta property="twitter:title" content={opengraphTitle || title} />
      )}
    </Helmet>
  )
}
